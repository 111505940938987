import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";

import FormDesigner from "../vue/form_designer/App.vue";
import ListEditor from "../vue/list_editor/App.vue";

const componentMap = {
  "form-designer": FormDesigner,
  "list-editor": ListEditor
};

export default class extends Controller {
  static values = {
    component: String,
    props: Object
  };

  connect() {
    console.log("Mounting Vue component:", this.componentValue, this.propsValue)

    const component = componentMap[this.componentValue];
    const app = createApp(component, this.propsValue);
    app.mount(this.element);
  }
}
