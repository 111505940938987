<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { computedAsync } from "@vueuse/core";
import { useServiceConsoleStore } from "./store.ts";
import TopNav from "./TopNav.vue";
import _ from "lodash";
import { Customer } from "./types.ts";
import Database from "./Database.ts";

const db = inject("db") as Database;

const store = useServiceConsoleStore();

const appointments = computed(() => {
  return store.getAppointments();
});

const loadingCustomers = ref(true);
const customers = computedAsync(async () => {
  const customerIds = _.uniq(_.map(appointments.value, "customerId")) as number[];
  const customers = await Promise.all(customerIds.map((customerId) => db.getCustomer(customerId)));
  return _.keyBy(customers, "id");
}, null, loadingCustomers);
</script>

<template>
  <div id="service-console--home" class="grow flex flex-col space-y-4">
    <TopNav title="Service Console" />

    <div v-if="!loadingCustomers" class="flex flex-col space-y-2 px-4">
      <span class="font-semibold text-xl">Appointments</span>
      <ul
        v-if="appointments.length > 0"
        role="list"
        class="flex flex-col space-y-2"
      >
        <li
          v-for="appointment of appointments"
          class="shadow hover:bg-selected"
        >
          <router-link
            :to="`/appointments/${appointment.id}`"
            class="w-full flex justify-between p-4 border border-muted rounded bg-raised shadow"
          >
            <div class="w-full flex items-center space-x-2">
              <div class="w-full flex flex-col space-y-1">
                <span class="h2">{{ appointment.time }}</span>
                <span>{{ appointment.location?.address }}</span>
                <span
                  >Customer: {{ customers[appointment.customerId]?.name }}</span
                >
                <template v-if="appointment.contact">
                  <span>Contact: {{ appointment.contact.name }}</span>
                </template>
                <div class="pt-1 flex justify-between">
                  <span>{{ appointment.ticketIds.length }} ticket{{ appointment.ticketIds.length === 1 ? "" : "s" }}</span>

                  <div class="flex space-x-1">
                    <span
                      v-for="serviceCategory of appointment.serviceCategories"
                      :class="`${serviceCategory.color}-badge`"
                    >
                      {{ serviceCategory.name }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </li>
      </ul>
      <div v-else>
        <span>No appointments...</span>
      </div>
    </div>
  </div>
</template>
