<script setup lang="ts">
import { onMounted, ref } from "vue";
import {
  draggable,
  dropTargetForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { combine } from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
  attachClosestEdge,
  type Edge,
  extractClosestEdge,
} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import DropIndicator from "./DropIndicator.vue";
import GripVerticalIcon from "../bootstrap_icons/GripVerticalIcon.vue";

const { field, selected } = defineProps(["field", "selected"]);

type FieldState =
  | {
      type: "idle";
    }
  | {
      type: "preview";
      container: HTMLElement;
    }
  | {
      type: "is-dragging";
    }
  | {
      type: "is-dragging-over";
      closestEdge: Edge | null;
    };

const fieldDataKey = Symbol("field");
type TFieldData = { [fieldDataKey]: true; fieldId: TField["id"] };

function getFieldData(field: TField): TFieldData {
  return { [fieldDataKey]: true, fieldId: field.id };
}

const stateStyles: { [Key in FieldState["type"]]?: string } = {
  "is-dragging": "opacity-40",
};

const idle: FieldState = { type: "idle" };
const elRef = ref<HTMLDivElement | null>(null);
const elState = ref<FieldState>(idle);

let cleanup = () => {};

onMounted(() => {
  if (elRef.value == null) {
    return;
  }

  cleanup = combine(
    draggable({
      element: elRef.value,
      getInitialData() {
        return getFieldData(field);
      },
      onDragStart() {
        elState.value = { type: "is-dragging" };
      },
      onDrop() {
        elState.value = idle;
      },
    }),
    dropTargetForElements({
      element: elRef.value,
      canDrop({ source }) {
        if (source.element === elRef.value) {
          return false;
        }

        return true;
      },
      getData({ input }) {
        const data = getFieldData(field);
        return attachClosestEdge(data, {
          element: elRef.value!,
          input,
          allowedEdges: ["top", "bottom"],
        });
      },
      getIsSticky() {
        return true;
      },
      onDragEnter({ self }) {
        const closestEdge = extractClosestEdge(self.data);
        elState.value = { type: "is-dragging-over", closestEdge };
      },
      onDrag({ self }) {
        const closestEdge = extractClosestEdge(self.data);

        if (
          elState.value.type !== "is-dragging-over" ||
          elState.value.closestEdge !== closestEdge
        ) {
          elState.value = { type: "is-dragging-over", closestEdge };
        }
      },
      onDragLeave() {
        elState.value = idle;
      },
      onDrop() {
        elState.value = idle;
      },
    }),
  );
});
</script>

<template>
  <div class="relative">
    <div
      ref="elRef"
      class="flex text-sm flex-row items-center border border-solid rounded p-2 hover:bg-selected hover:cursor-gap"
      :class="[stateStyles[elState.type] ?? '', { 'border-orange-500': selected }]"
    >
      <div class="w-6 flex justify-center">
        <GripVerticalIcon class="" />
      </div>

      <div class="truncate flex-grow flex-shrink flex">
        <span class="grow truncate">
          {{ field.label }}
        </span>

        <span class="uppercase text-xs">
          {{ field.type }}
        </span>
      </div>

      <DropIndicator v-if="elState.type === 'is-dragging-over' && elState.closestEdge" :edge="elState.closestEdge" gap="8px" />
    </div>
  </div>
</template>
