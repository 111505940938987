<script setup>
const { params } = defineProps(["node"]);

function handleClick(e) {
  // Deleting by object reference (which is slow).
  // https://www.ag-grid.com/javascript-data-grid/data-update-transactions/#using-object-references-slower
  // OPTIMIZE: Delete by Row ID.
  params.api.applyTransaction({ remove: [params.node.data] });
}
</script>

<template>
  <div class="h-full flex justify-center items-center">
    <button type="button" @click="handleClick" class="px-3 py-1.5">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="size-4"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18 18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</template>
