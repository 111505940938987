import { Controller } from "@hotwired/stimulus";
import { createApp } from "vue";
import { createPinia } from "pinia";

import Database from "./service_console/Database";
import { router } from "./service_console/router.ts";
import PhotoProcessingWorkerClient from "../workers/PhotoProcessingWorkerClient";

import App from "./service_console/App.vue";

export default class extends Controller {
  static values = {
    organizationId: Number,
    organizationSlug: String,
    photoProcessingWorkerUrl: String,
  };

  declare organizationIdValue: number;
  declare organizationSlugValue: string;
  declare photoProcessingWorkerUrlValue: string;

  async connect() {
    const organizationSlug = this.organizationSlugValue;

    const db = new Database(organizationSlug);
    await db.open();

    const photoProcessor = new PhotoProcessingWorkerClient(
      this.photoProcessingWorkerUrlValue,
    );

    const pinia = createPinia();

    const app = createApp(App, { organizationId: this.organizationIdValue });

    app.use(pinia);
    app.use(router);

    app.provide("db", db);
    app.provide("photoProcessor", photoProcessor);

    app.mount(this.element);
  }
}
