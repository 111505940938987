<script setup>
import _ from "lodash";

import GenericInputForm from "./GenericInputForm.vue";
import PhotosFieldForm from "./PhotosFieldForm.vue";
import SelectFieldForm from "./SelectFieldForm.vue";
import TextFieldForm from "./TextFieldForm.vue";
import TireModelFieldForm from "./TireModelFieldForm.vue";
import TireSizeFieldForm from "./TireSizeFieldForm.vue";
import TreadDepthFieldForm from "./TreadDepthFieldForm.vue";
import SinglePhotoFieldForm from "./SinglePhotoFieldForm.vue";

const { field } = defineProps(["field"]);

if (!field.visibility) field.visibility = { when: "always", conditions: [] };
if (!field.flaggingRule) field.flaggingRule = { mode: "any", conditions: [] };

const componentMap = {
  brake_pad_measurement: GenericInputForm,
  photo: SinglePhotoFieldForm,
  photos: PhotosFieldForm,
  select: SelectFieldForm,
  text: TextFieldForm,
  tire_model: TireModelFieldForm,
  tire_size: TireSizeFieldForm,
  tread_depth: TreadDepthFieldForm,
};

function lookupComponent(type) {
  const component = componentMap[type];
  if (component) return component;
  throw new Error(`Unsupported field form: ${type}`);
}
</script>

<template>
  <div class="flex justify-end">
    <span
      class="block text-sm font-medium leading-6 text-default text-zinc-500 uppercase"
      >{{ field.type }} field</span
    >
  </div>

  <component :is="lookupComponent(field.type)" :field="field" />

  <pre class="mt-4 font-mono text-xs hidden">{{ field }}</pre>
  <pre class="mt-4 font-mono text-xs hidden">{{
    _.pick(field, ["validate", "validateWhen", "visible", "visibleWhen"])
  }}</pre>
</template>
