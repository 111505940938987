<script setup lang="ts">
const { label } = defineProps(["label"]);

const model = defineModel();
</script>

<template>
  <div class="inspection-item-field">
    <label class="flex flex-col space-y-2">
      <span class="font-semibold text-xl">{{ label }}</span>

      <input
        v-model="model"
        class="px-3 py-1.5 grow border border-muted rounded-md"
      />
    </label>
  </div>
</template>
