<script setup lang="ts">
const { label, name, options } = defineProps(["label", "name", "options"]);

const model = defineModel({ default: null });
</script>

<template>
  <div class="inspection-item-field flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <div v-for="option in options" :key="option.value">
      <label
        class="group block p-2 border-2 border-zinc-300 rounded-md has-[:checked]:border-black"
      >
        <input
          type="radio"
          v-model="model"
          :id="name"
          :value="option.value"
          class="hidden"
        />
        {{ option.label }}
      </label>
    </div>
  </div>
</template>
