<script setup lang="ts">
import { computed, inject } from "vue";
import { useServiceConsoleStore } from "./store";
import { useTicketOperationsStore } from "./ticketOperations";

import CheckCircleIcon from "../../vue/heroicons/outline/CheckCircleIcon.vue";
import SparklesIcon from "../../vue/heroicons/outline/SparklesIcon.vue";

const store = useServiceConsoleStore();
const ticketOperationsStore = useTicketOperationsStore();

const activityCount = inject("activityCount");

const pendingTicketOperationsCount = computed(
  () => ticketOperationsStore.ticketOperations.size,
);
</script>

<template>
  <div class="flex space-x-4 justify-end pr-4">
    <span v-if="activityCount == 0" class="ticket-view--quiet">
      <SparklesIcon class="size-6 text-blue-600 sr-only" />
    </span>
    <span v-else aria-label="loading">
      <svg
        class="animate-spin -ml-1 mr-3 h-5 w-5 text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>

    <CheckCircleIcon
      v-if="pendingTicketOperationsCount == 0"
      class="ticket-view--pristine size-6"
    />
    <span v-else>
      <span
        class="inline-flex items-center rounded-full bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10"
      >
        {{ pendingTicketOperationsCount }}
      </span>
    </span>
  </div>
</template>
