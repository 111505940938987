<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted, ref } from "vue";
import { useTicketFocus } from "./ticketFocus.ts";
import Field from "./Field.vue";
import { itemSchemaToYupSchema2 } from "../../form_designer/utils";

const model = defineModel();

const { task, advanceFromTask } = defineProps(["task", "advanceFromTask"]);

const { hasTicketFocus } = useTicketFocus();

function validateAndAdvance() {
  if (valid) {
    model.value = { ...data.value };
    advanceFromTask(task);
  }
}

const hasFocus = computed(() => hasTicketFocus(`task-${task.id}`));

const data = ref({ ...model.value });
const errors = ref([]);

const validationSchema = itemSchemaToYupSchema2(task.schema);

const valid = computed(() => {
  try {
    validationSchema.validateSync(data.value, { abortEarly: false });
    errors.value = [];
    return true;
  } catch (err) {
    errors.value = err.errors;
    return false;
  }
});

onMounted(() => {
  task.schema.fields.forEach((field) => {
    const fieldName = _.replace(field.name, ".", "_");
    data[fieldName] ||= null;
  });
});
</script>

<template>
  <div
    class="service-task-content pt-4 flex flex-col space-y-6"
    :class="{ hidden: !hasFocus }"
  >
    <template v-for="field in task.schema.fields">
      <Field
        :field="field"
        v-model="data[_.replace(field.name, '.', '_')]"
      />
    </template>

    <div v-if="errors" v-for="error in errors" class="hidden">
      <span>{{ error }}</span>
    </div>

    <button
      @click.stop="validateAndAdvance"
      class="rounded-md w-full py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      :class="{ 'bg-gray-300': !valid, 'bg-indigo-600': valid }"
    >
      Next
    </button>
  </div>
</template>
