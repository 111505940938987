import * as Yup from "yup";
import { ItemSchema } from "./types";
import _ from "lodash";

export function itemSchemaToYupSchema2(itemSchema: ItemSchema) {
  const s = {};

  for (const field of itemSchema.fields) {
    if (typeof field.required === "boolean") {
      if (field.required == true) {
        if (field.visibility.when == "conditional") {
          const condition = field.visibility.conditions[0];
          if (condition) {
            s[_.replace(field.name, ".", "_")] = {
              validation: Yup.string()
                .nullable()
                .when(condition.field, {
                  is: condition.value,
                  then: (schema) => schema.required(),
                }),
            };
          }
        } else {
          s[_.replace(field.name, ".", "_")] = {
            validation: Yup.string()
              .nullable()
              .required(`${field.name} is required`),
          };
        }
      }
    }
  }

  function convertToYup(obj) {
    const shape = {};
    for (const key in obj) {
      if (obj[key]?.validation) {
        shape[key] = obj[key].validation;
      } else {
        shape[key] = convertToYup(obj[key]); // Recursive call for nested objects
      }
    }
    return Yup.object().shape(shape);
  }

  return convertToYup(s);
}

export function isFieldVisible2(field, values) {
  values ||= {};

  // console.log("values:", values);

  if (field.visibility.when == "conditional") {
    return _.every(field.visibility.conditions, (condition) => {
      if (condition.operator == "==") {
        return values[_.replace(condition.field, ".", "_")] == condition.value;
      }
    });
  } else {
    return true;
  }
}

function isFieldFlagged(field, values) {
  if (!field.flaggingRule) return false;

  const checkCondition = (condition) => {
    switch (condition.operator) {
      case "==":
        return _.get(values, field.name) == condition.value;
      default:
        throw new Error(
          `Unsupported flagging rule operator: ${condition.operator}`,
        );
    }
  };

  if (field.flaggingRule.mode == "any") {
    return _.some(field.flaggingRule.conditions, checkCondition);
  } else {
    return _.every(field.flaggingRule.conditions, checkCondition);
  }
}

export function isItemFlagged(item, values) {
  const result = _.some(item.schema.fields, (field) => isFieldFlagged(field, values));

  return result;
}
