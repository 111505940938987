import { Controller } from '@hotwired/stimulus';
import { debounce } from 'lodash';

export default class extends Controller {
  static targets = ['form', 'query', 'loading', 'results'];

  initialize() {
    this.search = debounce(this.search, 200).bind(this);
  }

  process() {
    if (this.queryTarget.value == '' || this.queryTarget.value.trim().length >= this.minCharacters) {
      this.loading();
      this.search();
    }
  }

  loading() {
    this.hide(this.resultsTarget);
    this.show(this.loadingTarget);
  }

  search() {
    this.formTarget.requestSubmit();
    this.hide(this.loadingTarget);
    this.show(this.resultsTarget)
  }

  show(target) {
    target.classList.remove("hidden");
  }

  hide(target) {
    target.classList.add("hidden");
  }

  handleOutsideClick(event) {
    if (this.element === event.target || this.element.contains(event.target)) {
      return;
    }

    this.loadingTarget.classList.add("hidden");
    this.resultsTarget.classList.add("hidden");
  }

  get minCharacters() {
    return 2;
  }
}
