<script setup lang="ts">
import _ from "lodash";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.ts";
import { router } from "./router.ts";
import TopNav from "./TopNav.vue";
import BuildingOfficeIcon from "../../vue/heroicons/outline/BuildingOfficeIcon.vue";
import CalendarIcon from "../../vue/heroicons/outline/CalendarIcon.vue";
import ClipboardIcon from "../../vue/bootstrap_icons/ClipboardIcon.vue";
import MapIcon from "../../vue/heroicons/outline/MapIcon.vue";
import PersonRolodexIcon from "../../vue/bootstrap_icons/PersonRolodexIcon.vue";
import ToolsIcon from "../../vue/bootstrap_icons/ToolsIcon.vue";

const props = defineProps(["id"]);

const store = useServiceConsoleStore();
const { getAppointment, getCustomer, getTicket } = storeToRefs(store);
const appointment = getAppointment.value(parseInt(props.id));

if (_.isNil(appointment)) router.push("/");

const customer = getCustomer.value(appointment!.customerId);
const tickets = appointment!.ticketIds.map((id) => getTicket.value(id));
</script>

<template>
  <div class="grow flex flex-col space-y-4">
    <TopNav title="Appointment" backRoute="/" />

    <div class="px-4 flex flex-col space-y-2">
      <div class="flex items-center space-x-2">
        <CalendarIcon class="size-5" />

        <span>{{ appointment!.formattedDate }}</span>
        <span>{{ appointment!.time }}</span>
      </div>

      <div class="flex items-center space-x-2">
        <BuildingOfficeIcon class="size-5" />
        <span>{{ customer.name }}</span>
      </div>

      <div class="flex items-center space-x-2">
        <MapIcon class="size-6" />

        <span class="grow">{{ appointment!.location?.address }}</span>

        <template v-if="appointment!.location?.address">
          <a
            :href="`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(appointment!.location.address)}`"
            target="_blank"
            class="flex-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 92.3 132.3"
              class="size-8"
            >
              <path
                fill="#1a73e8"
                d="M60.2 2.2C55.8.8 51 0 46.1 0 32 0 19.3 6.4 10.8 16.5l21.8 18.3L60.2 2.2z"
              />
              <path
                fill="#ea4335"
                d="M10.8 16.5C4.1 24.5 0 34.9 0 46.1c0 8.7 1.7 15.7 4.6 22l28-33.3-21.8-18.3z"
              />
              <path
                fill="#4285f4"
                d="M46.2 28.5c9.8 0 17.7 7.9 17.7 17.7 0 4.3-1.6 8.3-4.2 11.4 0 0 13.9-16.6 27.5-32.7-5.6-10.8-15.3-19-27-22.7L32.6 34.8c3.3-3.8 8.1-6.3 13.6-6.3"
              />
              <path
                fill="#fbbc04"
                d="M46.2 63.8c-9.8 0-17.7-7.9-17.7-17.7 0-4.3 1.5-8.3 4.1-11.3l-28 33.3c4.8 10.6 12.8 19.2 21 29.9l34.1-40.5c-3.3 3.9-8.1 6.3-13.5 6.3"
              />
              <path
                fill="#34a853"
                d="M59.1 109.2c15.4-24.1 33.3-35 33.3-63 0-7.7-1.9-14.9-5.2-21.3L25.6 98c2.6 3.4 5.3 7.3 7.9 11.3 9.4 14.5 6.8 23.1 12.8 23.1s3.4-8.7 12.8-23.2"
              />
            </svg>
          </a>
        </template>
      </div>

      <div class="flex items-center space-x-2">
        <PersonRolodexIcon class="size-5" />
        <template v-if="appointment.contact">
          <span>{{ appointment.contact?.name }}</span>
          <span v-if="appointment.contact?.phoneNumber">{{ appointment.contact?.phoneNumber }}</span>
        </template>
        <span v-else class="text-muted italic">N/A</span>
      </div>
    </div>

    <div class="px-4 flex flex-col space-y-2">
      <span class="font-semibold text-xl">Tickets</span>

      <div v-for="ticket of tickets" class="flex flex-col space-y-2">
        <router-link
          :to="`/appointments/${appointment!.id}/tickets/${ticket.id}`"
          class="w-full px-2 py-2.5 flex flex-col space-y-2 border border-muted bg-raised shadow"
        >
          <div class="flex space-x-2">
            <span class="grow"
              >{{ ticket.prefixedNumber }} {{ ticket.description }}</span
            >
            <span>{{ _.startCase(ticket.state) }}</span>
          </div>
          <div class="flex space-x-2">
            <span class="grow">{{ ticket.vehicle?.description }}</span>
          </div>

          <div class="flex flex-col space-y-2">
            <div
              v-for="inspection of ticket.inspections"
              class="flex items-center space-x-2"
            >
              <ClipboardIcon class="size-3" />

              <span class="text-sm">{{ inspection.description }}</span>
            </div>
            <div
              v-for="service of ticket.services"
              class="flex items-center space-x-2"
            >
              <ToolsIcon class="size-3" />

              <span class="grow text-sm">{{ service.description }}</span>

              <span
                v-if="service.serviceCategory"
                :class="`${service.serviceCategory.color}-badge`"
                >{{ service.serviceCategory.name }}</span
              >
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
