<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.ts";
import { Vehicle } from "./types.ts";

const { customerId, onSelect, onDismiss } = defineProps<{customerId: number, onSelect: any, onDismiss: any}>()

const store = useServiceConsoleStore();

const vehicles = ref<Vehicle[]>([]);

function filterVehicles(query: string) {
  vehicles.value = store.searchVehicles(query);
}
</script>

<template>
  <div class="relative flex flex-col space-y-2">
    <label class="flex space-x-2 items-center">
      <span class="font-semibold">Search vehicles</span>

      <input type="text" @input="(event) => filterVehicles(event.target!.value)" class="grow px-3 py-1.5 border rounded-md">
    </label>

    <div v-if="vehicles.length > 0" class="absolute top-12 left-0 right-0 bg-raised border shadow z-30">
      <div v-for="vehicle in vehicles" class="flex flex-col divide-y divide-muted">
        <a @click="onSelect(vehicle)" class="p-2 flex justify-between cursor-pointer hover:bg-selected">
          <span>{{ vehicle.vin }}</span>
          <span>{{ vehicle.description }}</span>
        </a>
      </div>
    </div>

    <div class="flex justify-end">
      <button @click="onDismiss" class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm">
        Cancel
      </button>
    </div>
  </div>
</template>
