<script setup lang="ts">
import { computed } from "vue";
import { useTicketFocus } from "./ticketFocus.ts";
import CheckCircleFillIcon from "../../vue/bootstrap_icons/CheckCircleFillIcon.vue";
import CircleIcon from "../../vue/bootstrap_icons/CircleIcon.vue";

const { description, focusKey, isValid } = defineProps([
  "description",
  "focusKey",
  "isValid",
]);

const { hasTicketFocus, registerTicketElement, setTicketFocus } =
  useTicketFocus();

const hasFocus = computed(() => hasTicketFocus(focusKey));
</script>

<template>
  <div
    class="p-4 shadow"
    :class="{ 'bg-neutral': !hasFocus, 'bg-raised': hasFocus }"
    @click="setTicketFocus(focusKey)"
  >
    <div
      :ref="(el) => registerTicketElement(focusKey, el)"
      class="flex justify-between scroll-mt-24"
    >
      <div class="flex space-x-2 items-center font-semibold">
        <div v-if="isValid">
          <CheckCircleFillIcon class="size-5 text-success" />
        </div>
        <div v-else>
          <CircleIcon class="size-5 text-muted" />
        </div>
        <span>{{ description }}</span>
      </div>
    </div>

    <slot />
  </div>
</template>
