<script setup>
import _ from "lodash";
import { computed, ref } from "vue";
import { AgGridVue } from "ag-grid-vue3";
import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import { scrollToLastRowInAgGrid } from "./ag-grid-utils.js";
import AgGridCellDeleteButton from "./AgGridCellDeleteButton.vue";

ModuleRegistry.registerModules([AllCommunityModule]);

function generateId() {
  return Math.random().toString(36).substr(2, 6);
}

const columns = ref(
  _.map(columnNames, (columnName) => {
    return { id: generateId(), name: columnName }
  })
)

const { columnNames, columnNamesInputName, items, itemsInputName } =
  defineProps([
    "columnNames",
    "columnNamesInputName",
    "items",
    "itemsInputName",
  ]);

const rowData = ref(
  _.map(items, (item) =>
    _.fromPairs(_.zip(_.map(columns.value, "id"), item))
  ),
);

const columnDefinitions = computed(() => {
  return [
    ..._.map(columns.value, (column, index) => {
      return {
        field: column.id,
        headerName: column.name,
        flex: 1,
        editable: true,
        rowDrag: index == 0,
      };
    }),
    { cellRenderer: AgGridCellDeleteButton, width: 64, resizable: false },
  ];
});

const grid = ref(null);

const updatedItems = ref(null);

function addColumn() {
  columns.value.push({
    id: generateId(),
    name: `Column ${columns.value.length + 1}`,
  });
}

function removeColumn(index) {
  _.pullAt(columns.value, [index]);
}

function addRow() {
  grid.value.api.applyTransaction({ add: [{}] });
  scrollToLastRowInAgGrid(grid.value);
}

function handleGridUpdated(e) {
  const rows = [];
  grid.value.api.forEachNodeAfterFilterAndSort((rowNode) => {
    rows.push(
      _.map(
        columns.value,
        (column) => rowNode.data[column.id],
      ),
    );
  });
  updatedItems.value = rows;
}

const updatedItemsJSON = computed(() => {
  return JSON.stringify(updatedItems.value);
});

const columnNamesJSON = computed(() => {
  return JSON.stringify(_.map(columns.value, "name"));
});
</script>

<template>
  <input :name="columnNamesInputName" type="hidden" v-model="columnNamesJSON" />
  <input :name="itemsInputName" type="hidden" v-model="updatedItemsJSON" />

  <div class="w-full flex space-x-6">
    <div class="w-1/4 flex flex-col space-y-2">
      <div class="flex">
        <div class="grow">
          <label :for="columnNamesInputName" class="text-sm font-semibold"
            >Columns</label
          >
        </div>

        <div>
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              @click="addColumn"
              class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              Add column
            </button>
          </span>
        </div>
      </div>

      <div class="flex flex-col divide-y border-y">
        <div
          v-for="(column, index) in columns"
          :key="index"
          class="flex space-x-2 py-2 px-1"
        >
          <span class="grow">
            <input type="text" v-model="column.name" class="w-full" />
          </span>

          <span class="flex items-center">
            <button type="button" @click="() => removeColumn(index)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="size-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6 18 18 6M6 6l12 12"
                />
              </svg>
            </button>
          </span>
        </div>
      </div>
    </div>

    <div
      v-if="columnDefinitions.length > 1"
      class="w-3/4 flex flex-col space-y-2"
    >
      <div class="flex justify-between">
        <div>
          <label :for="itemsInputName" class="text-sm font-semibold w-full">Rows</label>
        </div>

        <div>
          <span class="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              @click="addRow"
              class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
            >
              Add row
            </button>
          </span>
        </div>
      </div>

      <ag-grid-vue
        ref="grid"
        :rowDragManaged="true"
        :rowData="rowData"
        :columnDefs="columnDefinitions"
        :suppressMovableColumns="true"
        @modelUpdated="handleGridUpdated"
        @cellValueChanged="handleGridUpdated"
        style="height: 300px; width: 100%"
      />
    </div>

    <div v-else class="w-3/4 relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="mx-auto size-12 text-gray-400">
        <path stroke-linecap="round" stroke-linejoin="round" d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h7.5c.621 0 1.125-.504 1.125-1.125m-9.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-7.5A1.125 1.125 0 0 1 12 18.375m9.75-12.75c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125m19.5 0v1.5c0 .621-.504 1.125-1.125 1.125M2.25 5.625v1.5c0 .621.504 1.125 1.125 1.125m0 0h17.25m-17.25 0h7.5c.621 0 1.125.504 1.125 1.125M3.375 8.25c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m17.25-3.75h-7.5c-.621 0-1.125.504-1.125 1.125m8.625-1.125c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M12 10.875v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125M13.125 12h7.5m-7.5 0c-.621 0-1.125.504-1.125 1.125M20.625 12c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h7.5M12 14.625v-1.5m0 1.5c0 .621-.504 1.125-1.125 1.125M12 14.625c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m0 1.5v-1.5m0 0c0-.621.504-1.125 1.125-1.125m0 0h7.5" />
      </svg>

      <span class="mt-2 block text-sm font-semibold text-gray-900">Create a column</span>
    </div>
  </div>
</template>
