import { createRouter, createWebHashHistory } from "vue-router";

import HomeView from "./HomeView.vue";
import AppointmentView from "./AppointmentView.vue";
import TicketView from "./TicketView.vue";

const routes = [
  { path: "/", component: HomeView },
  { path: "/appointments/:id", component: AppointmentView, props: true },
  { path: "/appointments/:appointmentId/tickets/:id", component: TicketView, props: true },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      window.HSStaticMethods.autoInit();
    }, 100)
  }
});

export { router };