<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.ts";
import { Part } from "./types.ts";
import MagnifyingGlassIcon from "../../vue/heroicons/outline/MagnifyingGlassIcon.vue";

const { onSelect, onDismiss } = defineProps(["onSelect", "onDismiss"]);

const store = useServiceConsoleStore();
const { searchParts } = storeToRefs(store);

const parts = ref<Part[]>([]);

function filterParts(query: string) {
  parts.value = searchParts.value(query);
}
</script>

<template>
  <div
    class="fixed inset-0 bg-backdrop transition-opacity"
    aria-hidden="true"
  ></div>
  <div class="fixed inset-0 z-50 w-screen">
    <div
      class="flex min-h-full items-end justify-center text-center items-center p-8"
    >
      <div
        class="relative w-96 h-96 transform overflow-hidden rounded-lg shadow-xl bg-raised transition-all flex flex-col"
      >
        <div class="bg-emphasis flex px-3 py-1.5">
          <span class="text-md font-semibold text-on-emphasis">Add part</span>
        </div>

        <div class="h-full flex flex-col p-4 space-y-4">
          <label class="relative block w-full">
            <span class="sr-only">Search parts</span>
            <MagnifyingGlassIcon
              class="pointer-events-none absolute inset-y-0 left-2 h-full w-5 text-muted"
            />

            <input
              type="text"
              @input="(event) => filterParts(event.target!.value)"
              class="w-full px-3 py-1.5 pl-8 bg-base border rounded-md"
            />
          </label>

          <div class="h-52 flex flex-col overflow-y-auto divide-y divide-muted">
            <div v-for="part in parts" class="flex flex-col">
              <a
                @click="onSelect(part)"
                class="p-2 flex flex-col items-start cursor-pointer hover:bg-selected"
              >
                <div class="flex items-start">
                  <span>{{ part.brand }}</span>
                  <span>{{ part.number }}</span>
                </div>

                <span class="text-muted text-sm uppercase">{{
                  part.categoryName
                }}</span>
              </a>
            </div>
          </div>

          <div class="h-12 flex">
            <button @click="onDismiss">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
