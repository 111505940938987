<script setup lang="ts">
import _ from "lodash";
import { FlaggingRule } from '../../form_designer/types';

const { value }: { value: any } = defineProps<{
  value: FlaggingRule
}>();

value.conditions ||= [];

const operators = [
  "==",
  ">=",
  ">",
  "<=",
  "<"
]

function addCondition() {
  value.conditions.push({ operator: "==", value: "" })
}

function removeCondition(index) {
  _.pullAt(value.conditions, [index]);
}
</script>

<template>
  <div class="flex justify-between">
    <span class="block text-sm font-medium leading-6 text-default sm:pt-1.5">Conditions</span>

    <button
      type="button"
      @click="() => addCondition()"
      class="relative inline-flex items-center rounded-md bg-white px-2 py-1 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
    >
      Add condition
    </button>
  </div>

  <div class="flex flex-col space-y-1">
    <div v-for="(condition, index) in value.conditions" :key="index" class="flex space-x-2">
      <select v-model="condition.operator" class="block w-fit rounded-md px-2 py-1.5 bg-default border border-emphasis text-default shadow-sm sm:text-sm">
        <option v-for="operator in operators" :value="operator">{{ operator }}</option>
      </select>

      <input v-model="condition.value" class="block w-full rounded-md px-2 py-1.5 bg-default text-default border border-emphasis shadow-sm placeholder:text-muted sm:text-sm ring-emphasis ring-offset-2 ring-offset-emphasis focus:ring-2 outline-none" />

      <button type="button" @click="() => removeCondition(index)">
        <!-- heroicons/outline/trash -->
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5 text-zinc-400 hover:text-zinc-700">
          <path stroke-linecap="round" stroke-linejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
        </svg>
      </button>
    </div>
  </div>
</template>