import { get } from "@rails/request.js";
import { Appointment } from "./types";

class Backend {
  private organizationId: number;

  constructor(organizationId: number) {
    this.organizationId = organizationId;
  }

  public async getAppointments(): Promise<Appointment[]> {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/appointments.json`,
    );
    if (response.ok) {
      const json = await response.json;
      return json.appointments;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async getCustomers(): Promise<any[]> {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/customers.json`,
    );
    if (response.ok) {
      const json = await response.json;
      return json.customers;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async getLists(): Promise<any[]> {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/lists.json`,
    );
    if (response.ok) {
      const json = await response.json;
      return json.lists;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async getParts(): Promise<any[]> {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/parts.json`,
    );
    if (response.ok) {
      const json = await response.json;
      return json.parts;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async getTicket(id) {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/tickets/${id}.json`,
    );
    if (response.ok) {
      return await response.json;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async getVehicles(): Promise<any[]> {
    const response = await get(
      `/organizations/${this.organizationId}/service_console/vehicles.json`,
    );
    if (response.ok) {
      const json = await response.json;
      return json.vehicles;
    } else {
      throw new Error("Response not OK");
    }
  }

  public async applyTicketOperations(ticketId, operations) {
    console.log("applyTicketOperations", { ticketId, operations });

    const body = {
      operations: operations.map((operation) => ({
        ulid: operation.ulid,
        type: operation.type,
        payload: operation.payload,
      })),
    };

    try {
      const url = `/organizations/${this.organizationId}/service_console/tickets/${ticketId}/operations`;
      console.log("fetch", { url, body });
      const response = await window.fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.metaContent("csrf-token"),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        signal: AbortSignal.timeout(5000),
      });
      // console.log({ response });
      if (response.ok) {
        const body = await response.text();
        return JSON.parse(body);
        // return await response.json;
      } else {
        throw new Error("Response not OK");
      }
    } catch (error) {
      console.error(error);
      throw new Error("Error");
    }
  }

  public async uploadFile(filename, data) {
    try {
      const formData = new FormData();
      formData.append("file", this.dataURIToBlob(data));
      const url = `/organizations/${this.organizationId}/service_console/upload`;
      const response = await window.fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.metaContent("csrf-token"),
        },
        body: formData,
        signal: AbortSignal.timeout(5000),
      });
      const body = await response.text();
      return JSON.parse(body).signedId;
      // console.log({response})
    } catch (error) {
      console.error(error);
      throw new Error("Error");
    }
  }

  public async uploadTicketPhoto(ticketId, photoUlid, filename, data) {
    try {
      const formData = new FormData();
      formData.append("client_id", photoUlid);
      formData.append("file", this.dataURIToBlob(data));
      const url = `/organizations/${this.organizationId}/service_console/tickets/${ticketId}/photos`;
      const response = await window.fetch(url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": this.metaContent("csrf-token"),
        },
        body: formData,
        signal: AbortSignal.timeout(5000),
      });
      const body = await response.text();
      return JSON.parse(body).signedId;
      // console.log({response})
    } catch (error) {
      console.error(error);
      throw new Error("Error");
    }
  }

  private metaContent(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element && element.content;
  }

  private dataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
}

export default Backend;
