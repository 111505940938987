import { Controller } from "@hotwired/stimulus";
import { Loader } from "@googlemaps/js-api-loader";

const loader = new Loader({
  apiKey: "AIzaSyBjJUpnhz8R1OtydXOg1j8x4s8bUPE5g6U",
});

export default class extends Controller {
  static values = {
    placeId: String,
  };

  connect() {
    this.initMap();
  }

  async initMap() {
    const { Place } = await loader.importLibrary("places");
    const { Map } = await loader.importLibrary("maps");
    const { AdvancedMarkerElement } = await loader.importLibrary("marker");

    const place = new Place({ id: this.placeIdValue });
    await place.fetchFields({ fields: ["displayName", "location"] });

    const map = new Map(this.element, {
      mapId: "Default",
      center: place.location,
      zoom: 16,
    });

    new AdvancedMarkerElement({
      map,
      position: place.location,
      title: place.displayName,
    });
  }
}
