<script setup>
import { onErrorCaptured, ref } from 'vue';
import { router } from "./router.ts";
import ErrorBoundaryContent from "./ErrorBoundaryContent.vue";

const error = ref(null);
const errorMessage = ref("");

onErrorCaptured((err, instance, info) => {
  error.value = err;
  errorMessage.value = err.message;

  router.push("/");

  return false;
})
</script>

<template>
  <div v-if="error">
    Error: {{ errorMessage }}
  </div>
  <ErrorBoundaryContent v-else>
    <slot />
  </ErrorBoundaryContent>
</template>
