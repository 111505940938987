import "@hotwired/turbo-rails";
Turbo.session.drive = true;

import "../controllers";

import "preline/preline";
import HSCarousel from "@preline/carousel";
import HSSelect from "@preline/select";

document.addEventListener("turbo:load", function () {
  HSSelect.autoInit();
  HSCarousel.autoInit();
});

document.addEventListener("turbo:before-stream-render", function (event) {
  console.log("turbo:before-stream-render");

  const render = event.detail.render;
  event.detail.render = (streamElement) => {
    render(streamElement);
    HSSelect.autoInit();
  };
});

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}

import { StreamActions } from "@hotwired/turbo";

StreamActions.close_modal = function() {
  window.dispatchEvent(new Event("closeModal"));
}
