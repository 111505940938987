import _ from "lodash";
import Backend from "./Backend";

import { useServiceConsoleStore } from "./store";
import { useTicketOperationsStore } from "./ticketOperations";

class SyncManager {
  private db: any;
  private backend: Backend;

  constructor(organizationId, db) {
    this.db = db;
    this.backend = new Backend(organizationId);

    this.serviceConsoleStore = useServiceConsoleStore();
    this.ticketOperationsStore = useTicketOperationsStore();

    this.trigger = this.trigger.bind(this);

    this.nextSync = null;

    // setInterval(this.trigger, 1000);
  }

  public trigger() {
    // console.log("[SyncManager] trigger...")

    navigator.locks.request(
      "my_resource",
      { ifAvailable: true },
      async (lock) => {
        // console.log({lock})

        if (this.nextSync) {
          clearTimeout(this.nextSync);
        }

        if (!lock) {
          // console.log("Failed to acquire lock, will try again...");
          this.nextSync = setTimeout(this.trigger, 3000);
        } else {
          try {
            const done = await this.doSync();
            if (!done) {
              // console.log("Not done; Scheduling next sync");
              this.nextSync = setTimeout(this.trigger, 2000);
            }
          } catch (error) {
            console.error(error);
            this.nextSync = setTimeout(this.trigger, 3000);
          }
        }
      },
    );
  }

  private async doSync(): Promise<Boolean> {
    // console.log("doSync --> A")

    const pendingTicketOperations = await this.db.getPendingTicketOperations();
    // console.log(`${pendingTicketOperations.length} pending ticket operations...`, _.map(pendingTicketOperations, "ulid"))

    // console.log("doSync --> B")

    const ticketPhotos = await this.db.getTicketPhotos();
    for (const ticketPhoto of ticketPhotos) {
      if (!ticketPhoto.uploaded) {
        const file = await this.db.getFile(ticketPhoto.originalFileUlid);
        await this.backend.uploadTicketPhoto(
          ticketPhoto.ticketId,
          ticketPhoto.ulid,
          "photo.jpg",
          file.data,
        );
        this.db.markTicketPhotoAsUploaded(
          ticketPhoto.ticketId,
          ticketPhoto.ulid,
        );
      }
    }

    const readyTicketOperations = pendingTicketOperations;

    // console.log("doSync --> E")

    readyTicketOperations.forEach((ticketOperation) => {
      ticketOperation.payload = deepSnakeCaseKeys(ticketOperation.payload);
    });

    // console.log("doSync --> F")

    // console.log(`${readyTicketOperations.length} ready ticket operations...`)

    const groupedTicketOperations = _.groupBy(
      readyTicketOperations,
      "ticketId",
    );

    // console.log("doSync --> G")

    for (const ticketId in groupedTicketOperations) {
      try {
        // console.log("doSync --> G / 1")
        const ticketOperations = groupedTicketOperations[ticketId];

        // console.log({ ticketOperations })

        // console.log("doSync --> G / 2")

        const { ticket, appliedOperationUlids, unappliedOperationUlids } =
          await this.backend.applyTicketOperations(ticketId, ticketOperations);

        // console.log("doSync --> G / 3")

        _.each(unappliedOperationUlids, (ulid) =>
          console.error("Rejected ticket operation:", ulid),
        );

        // console.log("doSync --> G / 4")

        await this.db.putTicket(ticket);

        // console.log("doSync --> G / 5")

        await this.db.deleteTicketOperations(appliedOperationUlids);
        this.ticketOperationsStore.clearTicketOperations(appliedOperationUlids);

        // console.log("doSync --> G / 6")

        await this.db.rejectTicketOperations(unappliedOperationUlids);
        this.ticketOperationsStore.rejectTicketOperations(
          unappliedOperationUlids,
        );

        // console.log("doSync --> G / 7")

        if (appliedOperationUlids > 0) {
          // console.log(`[SyncManager] Applied ${appliedOperationUlids.length} ticket operation(s) for ticket ${ticketId}.`)
        }
        if (unappliedOperationUlids > 0) {
          console.log(
            `[SyncManager] Rejected ${unappliedOperationUlids.length} ticket operation(s) for ticket ${ticketId}.`,
          );
        }

        // console.log("doSync --> G / 8")

        this.serviceConsoleStore.setTicket(ticket);

        // console.log("doSync --> G / 9")
      } catch (error) {
        console.error(error);
        // console.log("doSync --> G [ERROR]", error)
      }
    }

    // console.log("doSync --> H")

    const remainingTicketOperations =
      await this.db.getPendingTicketOperations();
    if (remainingTicketOperations.length > 0) {
      return false;
    }

    return true;
  }
}

function deepSnakeCaseKeys(obj) {
  return _.transform(obj, (acc, value, key, target) => {
    const snakeCaseKey = _.isArray(target) ? key : _.snakeCase(key);
    acc[snakeCaseKey] = _.isObject(value) ? deepSnakeCaseKeys(value) : value;
  });
}

export default SyncManager;
