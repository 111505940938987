<script setup lang="ts">
import { computed, inject, nextTick, ref } from "vue";
import { useTicketOperationsStore } from "./ticketOperations.ts";
import { useTicketFocus } from "./ticketFocus.ts";
import TicketItem from "./TicketItem.vue";
import InspectionItem from "./InspectionItem.vue";
import ClipboardIcon from "../../vue/bootstrap_icons/ClipboardIcon.vue";
import _ from "lodash";
import { itemSchemaToYupSchema2 } from "../../form_designer/utils";

const { inspection, setInspectionItemData } = defineProps([
  "inspection",
  "setInspectionItemData",
]);

const ticketId = inject("ticketId");
const ticketOperationsStore = useTicketOperationsStore();

const { hasTicketFocus, setTicketFocus } = useTicketFocus();

function advanceFromItem(item) {
  const index = inspection.inspectionItems.findIndex((i) => i.id == item.id);

  if (index < inspection.inspectionItems.length - 1) {
    const nextItem = inspection.inspectionItems[index + 1];
    setTicketFocus(`item-${nextItem.id}`);
  }
}

const pendingTicketOperations = computed(() => {
  return ticketOperationsStore.ticketOperationsForTicketId(ticketId);
}, []);

const itemData = computed(() => {
  return _.map(inspection.inspectionItems, (inspectionItem) => {
    const key = `set-inspection-item-data--${inspectionItem.id}`;

    let data = inspectionItem.data || {};

    pendingTicketOperations.value.forEach((ticketOperation) => {
      if (ticketOperation.key == key) {
        data = ticketOperation.payload.data;
      }
    });

    return data;
  });
});

function isInspectionItemValid(schema, data) {
  const validationSchema = itemSchemaToYupSchema2(schema);
  return validationSchema.isValidSync(data);
}

const completedItemCount = computed(() => {
  return _.map(inspection.inspectionItems, (item, index) => {
    if (itemData[index]) return true;
    return false;
  }).length;
});
</script>

<template>
  <details open class="border border-muted">
    <summary
      class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10"
    >
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <ClipboardIcon class="size-4" />

          <span>{{ inspection.description }}</span>
        </div>

        <span class="hidden">
          {{ completedItemCount }}
          / {{ inspection.inspectionItems.length }}
        </span>
      </div>
    </summary>

    <div class="flex flex-col divide-y divide-muted">
      <TicketItem
        v-for="(inspectionItem, index) of inspection.inspectionItems"
        key="`item-${inspectionItem.id}`"
        :focusKey="`item-${inspectionItem.id}`"
        :description="inspectionItem.description"
        :isValid="
          isInspectionItemValid(inspectionItem.schema, inspectionItem.data)
        "
      >
        <InspectionItem
          v-if="hasTicketFocus(`item-${inspectionItem.id}`)"
          v-model="itemData[index]"
          v-on:update:modelValue="
            () =>
              setInspectionItemData(inspection, inspectionItem, itemData[index])
          "
          :item="inspectionItem"
          :advanceFromItem="advanceFromItem"
        />
      </TicketItem>
    </div>
  </details>
</template>
