<script setup lang="ts">
import VisibilityInput from './VisibilityInput.vue';

const { field } = defineProps(["field"]);
field.visibility ||= { when: "always" };
</script>

<template>
  <div class="flex flex-col space-y-4">
    <label class="flex flex-col space-y-2">
      <span class="block text-sm font-medium leading-6 text-default sm:pt-1.5">Name</span>
      <input type="text" v-model="field.name" class="block w-full rounded-md px-2 py-1.5 bg-default text-default border border-emphasis shadow-sm placeholder:text-muted sm:text-sm ring-emphasis ring-offset-2 ring-offset-emphasis focus:ring-2 outline-none" />
    </label>

    <label class="flex flex-col space-y-2">
      <span class="block text-sm font-medium leading-6 text-default sm:pt-1.5">Label</span>
      <input type="text" v-model="field.label" class="block w-full rounded-md px-2 py-1.5 bg-default text-default border border-emphasis shadow-sm placeholder:text-muted sm:text-sm ring-emphasis ring-offset-2 ring-offset-emphasis focus:ring-2 outline-none" />
    </label>

    <label class="flex flex-col space-y-2">
      <span class="block text-sm font-medium leading-6 text-default sm:pt-1.5">Required</span>
      <input type="checkbox" v-model="field.required" class="block w-full rounded-md px-2 py-1.5 bg-default text-default border border-emphasis shadow-sm placeholder:text-muted sm:text-sm ring-emphasis ring-offset-2 ring-offset-emphasis focus:ring-2 outline-none" />
    </label>

    <div class="flex flex-col space-y-2">
      <span class="block text-sm font-medium leading-6 text-default sm:pt-1.5">Visible</span>

      <VisibilityInput :value="field.visibility" />
    </div>
  </div>
</template>
