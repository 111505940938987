<script setup lang="ts">
import { inject, ref } from "vue";
import { computedAsync } from "@vueuse/core";
import Database from "./Database";
import Modal from "./Modal.vue";

const { ulid } = defineProps<{ ulid: string }>();
defineEmits(["remove"]);

const ticketId = inject("ticketId") as number;
const db = inject("db") as Database;
const showingPhoto = ref(false);

const photo = computedAsync(async () => {
  return await db.getTicketPhoto(ticketId, ulid);
}, null);

const thumbnailData = computedAsync(async () => {
  if (photo.value?.thumbnailFileUlid) {
    const thumbnailFile = await db.getFile(photo.value.thumbnailFileUlid);
    return thumbnailFile?.data;
  }
}, null, { lazy: true });

const originalData = computedAsync(async () => {
  if (photo.value?.originalFileUlid) {
    const originalFile = await db.getFile(photo.value.originalFileUlid);
    return originalFile?.data;
  }
}, null, { lazy: true });

function showPhoto() {
  showingPhoto.value = true;
}
</script>

<template>
  <template v-if="ulid && ulid.length > 0">
    <img
      v-if="thumbnailData"
      :src="thumbnailData"
      class="size-12 object-cover"
      @click="() => showPhoto(photo)"
    />
    <span v-else class="italic text-sm text-muted"
      >Thumbnail not available</span
    >
  </template>

  <Modal v-if="showingPhoto" :dismiss="() => showingPhoto = false">
    <img v-if="originalData" :src="originalData" />
    <button @click="$emit('remove')">Remove</button>
  </Modal>
</template>
