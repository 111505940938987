<script setup lang="ts">
import _ from "lodash";
import { computed, inject, ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.ts";
import { useTicketOperationsStore } from "./ticketOperations.ts";
import { useTicketFocus } from "./ticketFocus.ts";
import { itemSchemaToYupSchema2 } from "../../form_designer/utils";

import PartSelection from "./PartSelection.vue";
import ServiceTask from "./ServiceTask.vue";
import TicketItem from "./TicketItem.vue";
import ToolsIcon from "../../vue/bootstrap_icons/ToolsIcon.vue";

const {
  service,
  available,
  setServiceTaskData,
  onPartUsageSet,
  removePartUsage,
} = defineProps([
  "service",
  "available",
  "setServiceTaskData",
  "onPartUsageSet",
  "removePartUsage",
]);

const ticketId = inject("ticketId");

const { hasTicketFocus, setTicketFocus } = useTicketFocus();

const store = useServiceConsoleStore();
const ticketOperationsStore = useTicketOperationsStore();
const { getPart } = storeToRefs(store);

const showingPartSelection = ref(false);

function isTaskComplete(schema, data) {
  const validationSchema = itemSchemaToYupSchema2(schema);
  return validationSchema.isValidSync(data);
}

function advanceFromTask(task) {
  const index = service.tasks.findIndex((i) => i.id == task.id);

  if (index < service.tasks.length - 1) {
    const nextTask = service.tasks[index + 1];
    setTicketFocus(`task-${nextTask.id}`);
  }
}

const pendingTicketOperations = computed(() => {
  return ticketOperationsStore.ticketOperationsForTicketId(ticketId);
}, []);

const taskData = computed(() => {
  return _.map(service.tasks, (task) => {
    const key = `set-service-task-data--${task.id}`;

    let data = task.data || {};

    pendingTicketOperations.value.forEach((ticketOperation) => {
      if (ticketOperation.key == key) {
        data = ticketOperation.payload.data;
      }
    });

    return data;
  });
});
</script>

<template>
  <details v-if="!available">
    <summary
      class="h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none"
    >
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <ToolsIcon class="size-4" />

          <span>{{ service.description }}</span>
        </div>
      </div>
    </summary>

    <div class="border border-muted p-4">
      <span class="italic text-sm text-muted"
        >Pre-service inspections must be completed first.</span
      >
    </div>
  </details>

  <details v-else open class="border border-muted">
    <summary
      class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10"
    >
      <div class="grow flex justify-between items-center">
        <div class="pl-2 flex space-x-2 items-center">
          <ToolsIcon class="size-4" />

          <span>{{ service.description }}</span>
        </div>

        <span v-if="false">
          {{ service.tasks.filter(isTaskComplete).length }} /
          {{ service.tasks.length }}
        </span>
      </div>
    </summary>

    <div class="flex flex-col divide-y divide-muted">
      <!-- Part usages -->
      <div class="flex flex-col space-y-2 p-4">
        <span class="font-semibold">Parts</span>

        <div
          v-for="partUsage of service.partUsages.filter(
            (pu) => pu.quantity > 0,
          )"
          class="flex justify-between"
        >
          <div class="flex space-x-2">
            <span
              >{{ getPart(partUsage.partId)?.brand }}
              {{ getPart(partUsage.partId)?.number }}</span
            >
            <span>Qty: {{ partUsage.quantity }}</span>
          </div>

          <button
            type="button"
            @click="() => removePartUsage(partUsage)"
            class="text-accent underline"
          >
            Remove
          </button>
        </div>

        <div v-if="!showingPartSelection" class="flex justify-end">
          <button
            type="button"
            @click="showingPartSelection = true"
            class="text-accent"
          >
            Add part
          </button>
        </div>

        <PartSelection
          v-else
          :onSelect="
            (part) => {
              onPartUsageSet(part, 1);
              showingPartSelection = false;
            }
          "
          :onDismiss="() => (showingPartSelection = false)"
        />
      </div>

      <TicketItem
        v-for="(task, index) of service.tasks"
        key="`task-${task.id}`"
        :focusKey="`task-${task.id}`"
        :description="task.description"
        :isValid="isTaskComplete(task.schema, task.data)"
      >
        <ServiceTask
          v-if="hasTicketFocus(`task-${task.id}`)"
          v-model="taskData[index]"
          v-on:update:modelValue="
            () => setServiceTaskData(service, task, taskData[index])
          "
          :task="task"
          :advanceFromTask="advanceFromTask"
        />
      </TicketItem>
    </div>
  </details>
</template>
