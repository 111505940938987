import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = {
    customerChangeUrl: String
  }

  handleCustomerChange(event) {
    const params = new URLSearchParams()
    params.append("customer_id", event.detail.value);
    get(`${this.customerChangeUrlValue}?${params}`, { responseKind: "turbo-stream" });
  }
}
