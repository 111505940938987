<script setup lang="ts">
import { computed, inject, ref } from "vue";
import { computedAsync } from "@vueuse/core";
import _ from "lodash";
import Database from "./Database";

const { label } = defineProps(["label"]);

const ready = ref(false);

const tireBrand = ref(null);
const model = defineModel({ default: null });
const db = inject("db") as Database;

const tires = ref(null);

db.getList("Tires").then((list) => {
  tires.value = list.items;

  if (model.value) {
    const match = _.find(tires.value, (tire) => {
      return `${tire.Brand} ${tire.Model}` === model.value;
    });
    if (match) {
      tireBrand.value = match.Brand;
    }
  }

  ready.value = true;
});

const tireBrands = computed(() => {
  if (tires.value) {
    return _.chain(tires.value).map("Brand").uniq().sortBy(_.identity).value();
  } else {
    return null;
  }
});

const tireModels = computed(() => {
  if (tireBrand.value) {
    return _.chain(tires.value)
      .filter({ Brand: tireBrand.value })
      .map("Model")
      .sortBy(_.identity)
      .value();
  } else {
    return null;
  }
});
</script>

<template>
  <div class="inspection-item-field flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <div v-if="ready" class="grid grid-cols-2 gap-x-2">
      <span class="font-semibold text-sm">Brand</span>
      <span class="font-semibold text-sm">Model</span>

      <label class="h-fit mt-2 grid grid-cols-1">
        <span class="sr-only">Brand</span>

        <select
          v-model="tireBrand"
          @input="() => (model = null)"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        >
          <template v-if="tireBrands">
            <option v-for="tireBrand in tireBrands" :value="tireBrand">
              {{ tireBrand }}
            </option>
          </template>
        </select>

        <svg
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </label>

      <label class="h-fit mt-2 grid grid-cols-1">
        <span class="sr-only">Model</span>

        <select
          v-model="model"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        >
          <template v-if="tireModels">
            <option
              v-for="tireModel in tireModels"
              :value="`${tireBrand} ${tireModel}`"
            >
              {{ tireModel }}
            </option>
          </template>
        </select>

        <svg
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </label>
    </div>
  </div>
</template>
