<script setup>
import _ from "lodash";
import { computed, provide, ref, useTemplateRef } from "vue";
import Designer from "./Designer.vue";

const { schema, schemaInputName, lists } = defineProps([
  "schema",
  "schemaInputName",
  "lists",
]);

const schemaModel = ref(JSON.parse(JSON.stringify(schema)));

provide("lists", lists);

const updatedSchemaJSON = computed(() => {
  return JSON.stringify({
    fields: _.map(schemaModel.value.fields, (field) => {
      return _.omit(field, ["id", "_id"]);
    }),
  });
});
</script>

<template>
  <input :name="schemaInputName" type="hidden" v-model="updatedSchemaJSON" />

  <Designer v-model="schemaModel" />

  <pre class="font-mono text-xs hidden">{{
    JSON.parse(updatedSchemaJSON)
  }}</pre>
</template>
