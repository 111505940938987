<script setup lang="ts">
import _ from "lodash";
import { computed } from "vue";
import BrakePadMeasurementField from "./BrakePadMeasurementField.vue";
import PhotosField from "./PhotosField.vue";
import SelectField from "./SelectField.vue";
import SinglePhotoField from "./SinglePhotoField.vue";
import TextField from "./TextField.vue";
import TireModelInput from "./TireModelInput.vue";
import TireSizeInput from "./TireSizeInput.vue";
import TreadDepthInput from "./TreadDepthInput.vue";

const model = defineModel();

const { field } = defineProps(["field"]);

const fieldName = computed(() => _.replace(field.name, ".", "_"));
</script>

<template>
  <div class="grid grid-flow-col auto-cols-fr gap-x-4">
    <BrakePadMeasurementField
      v-if="field.type == 'brake_pad_measurement'"
      :label="field.label"
      v-model="model"
    />
    <PhotosField
      v-else-if="field.type == 'photos'"
      :label="field.label"
      v-model="model"
    />
    <SelectField
      v-else-if="field.type == 'select'"
      :label="field.label"
      v-model="model"
      :name="fieldName"
      :options="field.options"
    />
    <SinglePhotoField
      v-else-if="field.type == 'photo'"
      :label="field.label"
      v-model="model"
    />
    <TextField
      v-else-if="field.type == 'text'"
      :label="field.label"
      v-model="model"
    />
    <TireModelInput
      v-else-if="field.type == 'tire_model'"
      :label="field.label"
      v-model="model"
    />
    <TireSizeInput
      v-else-if="field.type == 'tire_size'"
      :label="field.label"
      v-model="model"
    />
    <TreadDepthInput
      v-else-if="field.type == 'tread_depth'"
      :label="field.label"
      v-model="model"
    />
    <span v-else>Unexpected field type: {{ field.type }}</span>
  </div>
</template>
