<script setup lang="ts">
import { computed, inject, provide, ref, Ref } from "vue";
import { storeToRefs } from "pinia";
import { useServiceConsoleStore } from "./store.ts";
import { useTicketOperationsStore } from "./ticketOperations.ts";
import { router } from "./router.ts";
import _ from "lodash";

import { provideTicketFocus } from "./ticketFocus.ts";

import Inspection from "./Inspection.vue";
import PhotoViewer from "./PhotoViewer.vue";
import Service from "./Service.vue";
import TopNav from "./TopNav.vue";
import VehicleSelection from "./VehicleSelection.vue";
import TruckIcon from "../../vue/bootstrap_icons/TruckIcon.vue";

import { Vehicle } from "./types.ts";
import SyncManager from "./SyncManager.ts";
import Database from "./Database.ts";
import { itemSchemaToYupSchema2 } from "../../form_designer/utils";

const props = defineProps(["appointmentId", "id"]);

provide("ticketId", _.toNumber(props.id));

const db = inject("db") as Database;
const syncManager = inject("syncManager") as SyncManager;

const store = useServiceConsoleStore();
const ticketOperationsStore = useTicketOperationsStore();

const activityCount = inject("activityCount") as Ref<number, number>;

const { getAppointment, getCustomer, getTicket } = storeToRefs(store);

const appointment = getAppointment.value(parseInt(props.appointmentId));
if (!appointment) router.push("/");

const ticket = ref(getTicket.value(parseInt(props.id)));
if (!ticket) router.push("/");

const customer = getCustomer.value(appointment!.customerId);
if (!customer) router.push("/");

const showingVehicleSelection = ref(_.isNil(ticket.value.vehicle?.id));
const showingPhoto = ref(false);
const showingPhotoRemoval = ref(null);

const currentPhoto = ref(null);

const currentTicketFocus = provideTicketFocus("");

function setTicketFocus(value: any) {
  currentTicketFocus.value = value;
}

function selectVehicle(vehicle: Vehicle) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "assign-vehicle",
      payload: { vehicle: vehicle },
    };

    // store.addTicketOperation(props.id, "assign-vehicle", ticketOperation);
    ticketOperationsStore.addTicketOperation(
      props.id,
      "assign-vehicle",
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    showingVehicleSelection.value = false;
    setTicketFocus("vin-photo");
    activityCount.value--;
    resolve();
  }).catch((error) => console.error(error));
}

function showPhoto(photo, onRemove) {
  showingPhoto.value = true;
  showingPhotoRemoval.value = onRemove;
  currentPhoto.value = photo;
}

provide("showPhoto", showPhoto);

function dismissPhoto() {
  showingPhoto.value = false;
  currentPhoto.value = null;
}

function setServicePartUsage(service, part, quantity) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-part-usage",
      payload: {
        serviceId: service.id,
        partId: part.id,
        quantity,
      },
    };
    // store.addTicketOperation(
    //   props.id,
    //   `set-service-part-usage--${service.id}--${part.id}`,
    //   ticketOperation,
    // );
    ticketOperationsStore.addTicketOperation(
      props.id,
      `set-service-part-usage--${service.id}--${part.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function removeServicePartUsage(service, partUsage) {
  activityCount.value++;

  new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-part-usage",
      payload: {
        serviceId: service.id,
        partId: partUsage.partId,
        quantity: 0,
      },
    };
    ticketOperationsStore.addTicketOperation(
      props.id,
      `set-service-part-usage--${service.id}--${partUsage.partId}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function finish() {
  const ticketOperation = { type: "submit", payload: {} };
  ticketOperationsStore.addTicketOperation(props.id, "submit", ticketOperation);
  applyTicketOperation(ticket.value, ticketOperation);
  syncManager.trigger();

  router.push("/");
}

async function applyTicketOperation(ticket, ticketOperation) {
  if (ticketOperation.type == "assign-vehicle") {
    const { vehicle } = ticketOperation.payload;
    ticket.vehicle = vehicle;
  } else if (ticketOperation.type === "set-inspection-item-data") {
    const { inspectionId, inspectionItemId, data } = ticketOperation.payload;

    const inspection = ticket.inspections.find((i) => i.id == inspectionId);
    const item = inspection.inspectionItems.find(
      (i) => i.id == inspectionItemId,
    );
    item.data = data;
  } else if (ticketOperation.type === "set-service-task-data") {
    const { serviceId, serviceTaskId, data } = ticketOperation.payload;

    const service = ticket.services.find((s) => s.id == serviceId);
    const task = service.tasks.find((t) => t.id == serviceTaskId);
    task.data = data;
  } else if (ticketOperation.type === "set-service-part-usage") {
    const { serviceId, partId, quantity } = ticketOperation.payload;

    const service = ticket.services.find((i) => i.id == serviceId);
    const partUsage = service.partUsages.find((pu) => pu.partId == partId);
    if (partUsage) {
      partUsage.quantity = quantity;
    } else {
      service.partUsages.push({ partId, quantity });
    }
  } else if (ticketOperation.type === "submit") {
    ticket.state = "submitted";
  } else {
    console.warn("Did not apply ticket operation", ticketOperation);
  }
}

function setInspectionItemData(inspection, inspectionItem, data) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-inspection-item-data",
      payload: {
        inspectionId: inspection.id,
        inspectionItemId: inspectionItem.id,
        data,
      },
    };
    ticketOperationsStore.addTicketOperation(
      props.id,
      `set-inspection-item-data--${inspectionItem.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

function setServiceTaskData(service, serviceTask, data) {
  activityCount.value++;

  return new Promise((resolve, _reject) => {
    const ticketOperation = {
      type: "set-service-task-data",
      payload: {
        serviceId: service.id,
        serviceTaskId: serviceTask.id,
        data,
      },
    };
    ticketOperationsStore.addTicketOperation(
      props.id,
      `set-service-task-data--${serviceTask.id}`,
      ticketOperation,
    );
    applyTicketOperation(ticket.value, ticketOperation);
    syncManager.trigger();

    resolve();
  })
    .catch((error) => console.error(error))
    .finally(() => activityCount.value--);
}

const servicesAreAvailable = computed(() => {
  return _.every(ticket.value.inspections, (inspection) => {
    return _.every(inspection.inspectionItems, (item) => {
      const validationSchema = itemSchemaToYupSchema2(item.schema);
      return validationSchema.isValidSync(item.data);
    });
  });
}, false);

const canFinish = computed(() => {
  const inspectionsComplete = _.every(
    ticket.value.inspections,
    (inspection) => {
      return _.every(inspection.inspectionItems, (item) => {
        const validationSchema = itemSchemaToYupSchema2(item.schema);
        return validationSchema.isValidSync(item.data);
      });
    },
  );

  if (!inspectionsComplete) return false;

  const servicesComplete = _.every(ticket.value.services, (service) => {
    return _.every(service.tasks, (task) => {
      const validationSchema = itemSchemaToYupSchema2(task.schema);
      return validationSchema.isValidSync(task.data);
    });
  });

  return servicesComplete;
}, false);
</script>

<template>
  <div class="grow flex flex-col overflow-y-scroll overscroll-none">
    <TopNav
      :title="`Ticket ${ticket.prefixedNumber}`"
      :backRoute="`/appointments/${appointment.id}`"
    />

    <template v-if="ticket.state === 'pending'">
      <details open class="border border-muted">
        <summary
          class="sticky top-12 h-10 px-2 flex items-center text-on-emphasis font-semibold bg-emphasis list-none z-10"
        >
          <div class="grow flex justify-between items-center">
            <div class="pl-2 flex space-x-2 items-center">
              <TruckIcon class="size-4" />
              <span>Vehicle</span>
            </div>

            <span>
              {{ ticket.vehicle.description }}
            </span>
          </div>
        </summary>

        <div class="flex flex-col divide-y divide-muted">
          <div class="p-4">
            <div
              v-if="ticket.vehicle?.id && !showingVehicleSelection"
              class="flex flex-col space-y-4"
            >
              <div class="grid grid-cols-2">
                <span>VIN</span>
                <span>{{ ticket.vehicle.vin }}</span>

                <span>Description</span>
                <span>{{ ticket.vehicle.description }}</span>
              </div>

              <div class="flex justify-end">
                <button
                  @click="() => (showingVehicleSelection = true)"
                  class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm"
                >
                  Select a different vehicle
                </button>
              </div>
            </div>

            <VehicleSelection
              v-if="showingVehicleSelection"
              :customerId="customer.id"
              :on-select="selectVehicle"
              :on-dismiss="() => (showingVehicleSelection = false)"
            />
          </div>
        </div>
      </details>

      <template
        v-for="inspection of ticket.inspections"
        :key="`inspection-${inspection.id}`"
      >
        <Inspection
          :inspection="inspection"
          :setInspectionItemData="setInspectionItemData"
        />
      </template>

      <template
        v-for="service of ticket.services"
        :key="`service-${service.id}`"
      >
        <Service
          :service="service"
          :available="servicesAreAvailable"
          :setServiceTaskData="setServiceTaskData"
          :onPartUsageSet="
            (part, quantity) => setServicePartUsage(service, part, quantity)
          "
          :removePartUsage="
            (partUsage) => removeServicePartUsage(service, partUsage)
          "
        />
      </template>

      <div v-if="showingPhoto">
        <PhotoViewer
          :db="db"
          :photo="currentPhoto"
          :onDismiss="dismissPhoto"
          :onRemove="showingPhotoRemoval"
        />
      </div>

      <div class="p-2 flex justify-end">
        <template v-if="canFinish">
          <button
            @click="finish"
            class="rounded-md bg-emphasis px-2.5 py-1.5 text-sm font-semibold text-on-emphasis shadow-sm"
          >
            Finish
          </button>
        </template>
      </div>
    </template>

    <template v-else>
      <div class="mx-auto p-8">
        <span class="text-xl italic">Ticket has been submitted and can no longer be updated.</span>
      </div>
    </template>
  </div>
</template>
