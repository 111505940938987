/**
 * Ticket Operations Pinia Store
 *
 */

import _ from "lodash";
import { computed, inject, ref, watchEffect } from "vue";
import { defineStore } from "pinia";
import Database from "./Database";
import { TicketOperation } from "./types";
import { ulid } from "ulid";

export const useTicketOperationsStore = defineStore("ticketOperations", () => {
  const db = inject("db") as Database;

  const ticketOperations = ref(new Map<string, TicketOperation>());

  watchEffect(async () => {
    console.log(
      `Persisting ${ticketOperations.value.size} ticket operations to the database...`,
    );

    for (const ticketOperation of ticketOperations.value.values()) {
      db.putTicketOperation(JSON.parse(JSON.stringify(ticketOperation)));
    }
  });

  const ticketOperationsForTicketId = computed<
    (ticketId: number) => TicketOperation[]
  >(() => {
    return (ticketId: number): TicketOperation[] =>
      [...ticketOperations.value.values()].filter(
        (ticketOperation) => ticketOperation.ticketId == ticketId,
      );
  });

  async function load(): Promise<void> {
    // TODO Detect/handle existence of rejected ticket operations.
    const ticketOps = await db.getPendingTicketOperations();

    console.log(
      `Loading ${ticketOps.length} ticket operations from database...`,
    );

    ticketOps.forEach((ticketOp) => {
      ticketOperations.value.set(ticketOp.ulid, ticketOp);
    });
  }

  function addTicketOperation(
    ticketId: number,
    key: string,
    operation: { type: string },
  ): void {
    const newUlid = ulid();
    ticketOperations.value.set(newUlid, {
      ...operation,
      ticketId,
      key,
      ulid: newUlid,
    });
  }

  function clearTicketOperations(ulids: string[]): void {
    ulids.forEach((ulid) => ticketOperations.value.delete(ulid));
  }

  function rejectTicketOperations(ulids: string[]): void {
    ticketOperations.value.forEach((ticketOperation) => {
      if (_.includes(ulids, ticketOperation.ulid)) {
        ticketOperation.rejected = true;
      }
    });
  }

  return {
    // state
    ticketOperations,

    // getters
    ticketOperationsForTicketId,

    // actions
    load,
    addTicketOperation,
    clearTicketOperations,
    rejectTicketOperations,
  };
});
