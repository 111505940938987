<script setup lang="ts">
import { inject } from "vue";
import { computedAsync } from "@vueuse/core";
import _ from "lodash";
import Database from "./Database";

const { label } = defineProps(["label"]);

const model = defineModel({ default: null });
const db = inject("db") as Database;

const brakePadMeasurements = computedAsync(async () => {
  const list = await db.getList("Brake Pad Measurements");
  return _.chain(list.items)
    .map("Value")
    .map(_.toInteger)
    .sortBy(_.identity)
    .value();
}, null);
</script>

<template>
  <div class="inspection-item-field flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <label class="h-fit mt-2 grid grid-cols-1">
      <span class="sr-only">Brake pad measurement</span>
      <select
        v-model="model"
        @input="() => (model = null)"
        class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
      >
        <template v-if="brakePadMeasurements">
          <option
            v-for="brakePadMeasurement in brakePadMeasurements"
            :value="brakePadMeasurement"
          >
            {{ brakePadMeasurement }}
          </option>
        </template>
      </select>

      <svg
        class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
        viewBox="0 0 16 16"
        fill="currentColor"
        aria-hidden="true"
        data-slot="icon"
      >
        <path
          fill-rule="evenodd"
          d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
          clip-rule="evenodd"
        />
      </svg>
    </label>
  </div>
</template>
