import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
// application.debug = true

import HwComboboxController from "@josefarias/hotwire_combobox"
application.register("hw-combobox", HwComboboxController)

window.Stimulus   = application

export { application }
