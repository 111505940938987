<script setup lang="ts">
import _ from "lodash";
import PhotoThumbnail from "./PhotoThumbnail.vue";
import PhotoDrop from "./PhotoDrop.vue";

const model = defineModel<Array<string>>({ required: true, default: [] });

const { label } = defineProps(["label"]);

function handlePhotoDrop({ photoUlid }: { photoUlid: string }) {
  model.value = [...model.value, photoUlid];
}
</script>

<template>
  <div class="inspection-item-field relative flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <div class="flex space-x-4">
      <div class="grow px-2 flex space-x-2 items-center border rounded">
        <template v-for="ulid of model" :key="ulid">
          <PhotoThumbnail :ulid="ulid" @remove="() => _.remove(model, (u) => u === ulid)" />
        </template>
      </div>

      <PhotoDrop label="Photo(s)" :onDrop="handlePhotoDrop" />
    </div>
  </div>
</template>
