import { defineStore } from "pinia";
import Fuse from "fuse.js";
import _ from "lodash";
import { Appointment, Customer, Part, Ticket, Vehicle } from "./types";

export const useServiceConsoleStore = defineStore("serviceConsole", {
  state: () => {
    return {
      appointments: [] as Appointment[],
      customers: {} as { [id: number]: Customer },
      tickets: {} as { [id: number]: Ticket },
      message: "",

      parts: {} as { [id: number]: Part },
      partsSearchIndex: null as Fuse<Part> | null,

      vehicles: {} as { [id: number]: Vehicle },
      vehiclesSearchIndex: null as Fuse<Vehicle> | null,
    };
  },
  getters: {
    getAppointments(state) {
      return () => _.sortBy(state.appointments, "timestamp");
    },

    getAppointment(state) {
      return (id: number) => state.appointments.find((a) => a.id === id);
    },

    getCustomer(state) {
      return (id: number) => state.customers[id];
    },

    getTicket(state) {
      return (id: number) => state.tickets[id];
    },

    getPart(state) {
      return (id: number) => state.parts[id];
    },

    searchParts(state) {
      return (query: string) =>
        state.partsSearchIndex!.search(query).map((i) => i.item);
    },

    searchVehicles(state) {
      return (query: string) =>
        state.vehiclesSearchIndex!.search(query).map((i) => i.item);
    }
  },
  actions: {
    setParts(parts: Part[]) {
      this.parts = _.keyBy(parts, "id");
    },

    rebuildPartsSearchIndex() {
      this.partsSearchIndex = new Fuse(_.values(this.parts), {
        keys: ["name", "description", "number"],
      });
    },

    setAppointments(appointments: Appointment[]) {
      this.appointments = appointments;
    },

    setCustomer(customer: Customer) {
      this.customers[customer.id] = customer;
    },

    setTicket(ticket: Ticket) {
      this.tickets[ticket.id] = ticket;
    },

    setMessage(message: string) {
      this.message = message;
    },

    setVehicles(vehicles: Vehicle[]) {
      this.vehicles = _.keyBy(vehicles, "id");
    },

    rebuildVehiclesSearchIndex() {
      this.vehiclesSearchIndex = new Fuse(_.values(this.vehicles), {
        keys: ["vin"],
        threshold: 0.0,
        ignoreLocation: true
      });
    },
  },
});
