<script setup lang="ts">
import _ from "lodash";
import { inject } from "vue";
import CameraIcon from "../../vue/heroicons/outline/CameraIcon.vue";

const { label, onDrop } = defineProps<{
  label: string;
  onDrop: Function;
}>();

const ticketId: any = inject("ticketId");
const db: any = inject("db");
const photoProcessor: any = inject("photoProcessor");

function handleFileChange(event) {
  const reader = new FileReader();
  reader.onload = () => processPhoto(reader.result);
  reader.readAsDataURL(event.target.files[0]);
}

async function processPhoto(data) {
  if (_.isNil(data)) return;

  const blob = readDataUrlAsBlob(data);

  const photoUlid = await db.addTicketPhoto(ticketId, data);

  // Generate thumbnail.
  const thumbnailData = await photoProcessor.resizeBlob({
    width: 32,
    height: 32,
    quality: 0.4,
    blob,
  });

  await db.setTicketPhotoThumbnail(ticketId, photoUlid, thumbnailData);

  onDrop({ photoUlid, thumbnailData });
}

function readDataUrlAsBlob(dataUrl) {
  var arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
</script>

<template>
  <label
    class="group relative flex items-center justify-center text-white bg-black rounded-full w-14 h-14 cursor-pointer hover:bg-zinc-700 dark:bg-blue-600 dark:hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 focus:outline-none dark:focus:ring-blue-800"
  >
    <span class="sr-only">{{ label }}</span>
    <CameraIcon class="size-8 transition-transform group-hover:rotate-3" />

    <input
      type="file"
      capture="environment"
      accept="image/*"
      @change="handleFileChange"
      class="absolute top-0 right-0 bottom-0 left-0 opacity-0"
    />
  </label>
</template>
