import { computed, reactive } from "vue";
import Backend from "./Backend";
import Database from "./Database";
import { useServiceConsoleStore } from "./store";

export default (organizationId: number, db: Database) => {
  const store = useServiceConsoleStore();

  const state = reactive({
    loadingMessage: null as string | null,
    loaded: false
  });

  const backend = new Backend(organizationId);

  async function load() {
    // Lists
    state.loadingMessage = "Syncing lists"
    const lists = await backend.getLists();
    await db.setLists(lists);

    // Parts
    state.loadingMessage = "Syncing parts"
    const parts = await backend.getParts();
    await db.setParts(parts);
    await store.setParts(parts);
    await store.rebuildPartsSearchIndex();

    // Customers
    state.loadingMessage = "Syncing customers"
    const customers = await backend.getCustomers();
    await db.setCustomers(customers);
    
    // Vehicles
    state.loadingMessage = "Syncing vehicles"
    const vehicles = await backend.getVehicles();
    await db.setVehicles(vehicles);
    await store.setVehicles(vehicles);
    await store.rebuildVehiclesSearchIndex();

    // Appointments
    state.loadingMessage = "Syncing appointments"
    const appointments = await backend.getAppointments();
    await db.setAppointments(appointments);
    await Promise.all(
      appointments.map(async (appointment) => {
        await Promise.all(
          appointment.ticketIds.map(async (ticketId) => {
            const ticket = await backend.getTicket(ticketId);
            await db.putTicket(ticket);
          }),
        );
      }),
    );

    store.setAppointments(appointments);

    await Promise.all(
      appointments.map(async (appointment) => {
        const customer = await db.getCustomer(appointment.customerId);
        if (customer) {
          store.setCustomer(customer);
  
          await Promise.all(
            appointment.ticketIds.map(async (ticketId) => {
              const ticket = await db.getTicket(ticketId);
              if (ticket) {
                store.setTicket(ticket);
              }
            }),
          );
        }
      }),
    );

    state.loadingMessage = null;
    state.loaded = true;
  }
  
  return {
    load,
    loadingMessage: computed(() => state.loadingMessage),
    loaded: computed(() => state.loaded)
  }
}
