import { openDB, DBSchema, IDBPDatabase } from "idb";
import { ulid } from "ulid";
import {
  Appointment,
  Customer,
  File,
  List,
  Part,
  Ticket,
  TicketOperation,
  TicketPhoto,
  Vehicle,
} from "./types";

interface Schema extends DBSchema {
  appointments: {
    key: number;
    value: Appointment;
  };

  customers: {
    key: number;
    value: Customer;
  };

  files: {
    key: string;
    value: File;
  };

  lists: {
    key: string;
    value: List;
  };

  parts: {
    key: number;
    value: Part;
  };

  tickets: {
    key: number;
    value: Ticket;
  };

  ticketOperations: {
    key: [number, string];
    value: TicketOperation;
    indexes: {
      ulid: string;
    };
  };

  ticketPhotos: {
    key: [number, string];
    value: TicketPhoto;
  };

  vehicles: {
    key: number;
    value: Vehicle;
    indexes: {
      customerId: number;
    };
  };
}

class Database {
  private name: string;
  private version: number;
  private db: IDBPDatabase<Schema> | null;

  constructor(organizationSlug: string) {
    this.name = `${organizationSlug}-service-console`;
    this.version = 5;
    this.db = null;
  }

  public async open() {
    const databaseName = this.name;

    this.db = await openDB<Schema>(databaseName, this.version, {
      upgrade(db, oldVersion, newVersion, _transaction, _event) {
        console.log(
          `Upgrading ${databaseName} database from version ${oldVersion} to ${newVersion}.`,
        );

        if (db.objectStoreNames.contains("appointments"))
          db.deleteObjectStore("appointments");
        db.createObjectStore("appointments", { keyPath: "id" });

        if (db.objectStoreNames.contains("tickets"))
          db.deleteObjectStore("tickets");
        db.createObjectStore("tickets", { keyPath: "id" });

        if (db.objectStoreNames.contains("customers"))
          db.deleteObjectStore("customers");
        db.createObjectStore("customers", { keyPath: "id" });

        if (db.objectStoreNames.contains("lists"))
          db.deleteObjectStore("lists");
        db.createObjectStore("lists", { keyPath: "name" });

        if (db.objectStoreNames.contains("parts"))
          db.deleteObjectStore("parts");
        db.createObjectStore("parts", { keyPath: "id" });

        if (db.objectStoreNames.contains("vehicles"))
          db.deleteObjectStore("vehicles");
        const vehiclesObjectStore = db.createObjectStore("vehicles", {
          keyPath: "id",
        });
        vehiclesObjectStore.createIndex("customerId", "customerId");

        // TODO Preserve files
        if (db.objectStoreNames.contains("files"))
          db.deleteObjectStore("files");
        db.createObjectStore("files", { keyPath: "ulid" });

        if (db.objectStoreNames.contains("ticketPhotos"))
          db.deleteObjectStore("ticketPhotos");
        db.createObjectStore("ticketPhotos", { keyPath: ["ticketId", "ulid"] });

        if (db.objectStoreNames.contains("ticketOperations"))
          db.deleteObjectStore("ticketOperations");
        const ticketOperationsStore = db.createObjectStore("ticketOperations", {
          keyPath: ["ticketId", "key"],
        });
        ticketOperationsStore.createIndex("ulid", "ulid", { unique: true });

        console.log(`Upgraded database to version ${newVersion}.`);
      },
      blocked(currentVersion, blockedVersion, event: IDBVersionChangeEvent) {
        console.log(
          `Database blocked: ${databaseName}. Cannot open database version ${blockedVersion} because version ${currentVersion} is open.`,
          event,
        );
      },
      blocking(currentVersion, blockedVersion, event: IDBVersionChangeEvent) {
        console.log(
          `Database blocking: ${databaseName}. A connection to version ${currentVersion} is blocking the opening of a new connection at version ${blockedVersion}.`,
          event,
        );
      },
      terminated() {
        console.warn(`Database connection terminated: ${databaseName}.`);
      },
    });
  }

  public async clear() {
    await this.db!.clear("appointments");
  }

  public async getAllAppointments() {
    return this.db!.getAll("appointments");
  }

  public async setAppointments(appointments: Appointment[]) {
    await this.db!.clear("appointments");
    await Promise.all(
      appointments.map(
        async (appointment) => await this.db!.put("appointments", appointment),
      ),
    );
  }

  public async setCustomers(customers: Customer[]) {
    await this.db!.clear("customers");
    await Promise.all(
      customers.map(async (customer) => {
        await this.db!.put("customers", customer);
      }),
    );
  }

  public async getCustomer(id: number) {
    return await this.db!.get("customers", id);
  }

  public async putCustomer(customer: Customer) {
    await this.db!.put("customers", customer);
  }

  public async setLists(lists: List[]) {
    await this.db!.clear("lists");
    await Promise.all(
      lists.map(async (list) => {
        await this.db!.put("lists", list);
      }),
    );
  }

  public async getList(name: string) {
    return await this.db!.get("lists", name);
  }

  public async putList(list: List) {
    await this.db!.put("lists", list);
  }

  public async setParts(parts: Part[]) {
    await this.db!.clear("parts");
    await Promise.all(
      parts.map(async (part) => {
        await this.db!.put("parts", part);
      }),
    );
  }

  public async getParts() {
    return await this.db!.getAll("parts");
  }

  public async getPart(id: number) {
    return await this.db!.get("parts", id);
  }

  public async setVehicles(vehicles: Vehicle[]) {
    await this.db!.clear("vehicles");
    await Promise.all(
      vehicles.map(async (vehicle) => {
        await this.db!.put("vehicles", vehicle);
      }),
    );
  }

  public async putVehicle(vehicle: Vehicle) {
    await this.db!.put("vehicles", vehicle);
  }

  public async getVehiclesByCustomerId(customerId: number) {
    const index = this.db!.transaction("vehicles").store.index("customerId");
    const vehicles = [];
    for await (const cursor of index.iterate(customerId)) {
      vehicles.push(cursor.value);
    }
    return vehicles;
  }

  public async getTicket(id: number) {
    return await this.db!.get("tickets", id);
  }

  public async putTicket(ticket: Ticket) {
    await this.db!.put("tickets", ticket);
  }

  public async putTicketOperation(ticketOperation: TicketOperation) {
    await this.db!.put("ticketOperations", ticketOperation);
  }

  public async getPendingTicketOperations() {
    const ticketOperations = await this.db!.getAll("ticketOperations");
    return ticketOperations.filter((ticketOperation) => {
      return !ticketOperation.rejected;
    });
  }

  public async deleteTicketOperations(ulids: string[]) {
    for (const ulid of ulids) {
      const record = await this.db!.getFromIndex(
        "ticketOperations",
        "ulid",
        ulid,
      );
      if (record) {
        await this.db!.delete("ticketOperations", [
          record.ticketId,
          record.key,
        ]);
      }
    }
  }

  public async rejectTicketOperations(ulids: string[]) {
    await Promise.all(
      ulids.map(async (ulid) => {
        const record = await this.db!.getFromIndex(
          "ticketOperations",
          "ulid",
          ulid,
        );
        if (record) {
          await this.db!.put("ticketOperations", { ...record, rejected: true });
        }
      }),
    );
  }

  // TODO Revise argument type definition
  public async addFile(file: any) {
    const newUlid = ulid();
    await this.db!.add("files", { ...file, ulid: newUlid });
    return newUlid;
  }

  // TODO Revise return type definition
  public async getFile(ulid: string) {
    return await this.db!.get("files", ulid);
  }

  // TODO Revise argument type definition
  public async addTicketPhoto(ticketId: number, originalPhotoData: any) {
    const photoUlid = ulid();

    const originalFileUlid = await this.addFile({
      data: originalPhotoData,
      description: `Photo ${photoUlid} original`,
    });

    await this.db!.add("ticketPhotos", {
      ticketId,
      ulid: photoUlid,
      originalFileUlid,
      uploaded: false,
    });

    return photoUlid;
  }

  public async markTicketPhotoAsUploaded(ticketId: number, photoUlid: string) {
    const photo = await this.getTicketPhoto(ticketId, photoUlid);
    if (photo) {
      await this.db!.put("ticketPhotos", { ...photo, uploaded: true });
    }
  }

  // TODO Revise argument type definition
  public async setTicketPhotoThumbnail(
    ticketId: number,
    photoUlid: string,
    thumbnailData: any,
  ) {
    const photo = await this.getTicketPhoto(ticketId, photoUlid);
    if (photo) {
      photo.thumbnailFileUlid = await this.addFile({
        data: thumbnailData,
        description: `Photo ${photoUlid} thumbnail`,
      });
      await this.db!.put("ticketPhotos", photo);
    }
  }

  public async getTicketPhotos() {
    return await this.db!.getAll("ticketPhotos");
  }

  public async getTicketPhoto(ticketId: number, ulid: string) {
    console.log({ ticketId, ulid });
    return await this.db!.get("ticketPhotos", [ticketId, ulid]);
  }
}

export default Database;
