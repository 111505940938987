<script setup lang="ts">
import { inject, ref } from "vue";
import { computedAsync } from "@vueuse/core";
import _ from "lodash";
import Database from "./Database";

const { label } = defineProps(["label"]);

const model = defineModel({ default: null });
const db = inject("db") as Database;

let parsedTireWidth = null;
let parsedTireRatio = null;
let parsedTireDiameter = null;

if (model.value) {
  const parsed = _.split(model.value, "/");
  parsedTireWidth = parsed[0];
  parsedTireRatio = parsed[1];
  parsedTireDiameter = parsed[2];
}

const tireWidth = ref(parsedTireWidth);
const tireRatio = ref(parsedTireRatio);
const tireDiameter = ref(parsedTireDiameter);

const tireWidths = computedAsync(async () => {
  const list = await db.getList("Tire Widths");
  return _.map(list.items, "Value");
}, null);

const tireRatios = computedAsync(async () => {
  const list = await db.getList("Tire Ratios");
  return _.map(list.items, "Value");
}, null);

const tireDiameters = computedAsync(async () => {
  const list = await db.getList("Tire Diameters");
  return _.map(list.items, "Value");
}, null);

function updateModel() {
  if (tireWidth.value && tireRatio.value && tireDiameter.value) {
    model.value = _.join(
      [tireWidth.value, tireRatio.value, tireDiameter.value],
      "/",
    );
  } else {
    model.value = null;
  }
}
</script>

<template>
  <div class="inspection-item-field flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <div class="grid grid-cols-3 gap-x-2">
      <span class="font-semibold text-sm">Width</span>
      <span class="font-semibold text-sm">Ratio</span>
      <span class="font-semibold text-sm">Diameter</span>

      <label class="h-fit mt-2 grid grid-cols-1">
        <span class="sr-only">Width</span>
        <select
          v-model="tireWidth"
          @update:model-value="updateModel"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        >
          <template v-if="tireWidths">
            <option v-for="tireWidth in tireWidths" :value="tireWidth">
              {{ tireWidth }}
            </option>
          </template>
        </select>

        <svg
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </label>

      <label class="h-fit mt-2 grid grid-cols-1">
        <span class="sr-only">Ratio</span>
        <select
          v-model="tireRatio"
          @update:model-value="updateModel"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        >
          <template v-if="tireRatios">
            <option v-for="tireRatio in tireRatios" :value="tireRatio">
              {{ tireRatio }}
            </option>
          </template>
        </select>

        <svg
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </label>

      <label class="h-fit mt-2 grid grid-cols-1">
        <span class="sr-only">Diameter</span>
        <select
          v-model="tireDiameter"
          @update:model-value="updateModel"
          class="col-start-1 row-start-1 w-full appearance-none rounded-md bg-white py-1.5 pl-3 pr-8 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6"
        >
          <template v-if="tireDiameters">
            <option v-for="tireDiameter in tireDiameters" :value="tireDiameter">
              {{ tireDiameter }}
            </option>
          </template>
        </select>

        <svg
          class="pointer-events-none col-start-1 row-start-1 mr-2 size-5 self-center justify-self-end text-gray-500 sm:size-4"
          viewBox="0 0 16 16"
          fill="currentColor"
          aria-hidden="true"
          data-slot="icon"
        >
          <path
            fill-rule="evenodd"
            d="M4.22 6.22a.75.75 0 0 1 1.06 0L8 8.94l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.25 3.25a.75.75 0 0 1-1.06 0L4.22 7.28a.75.75 0 0 1 0-1.06Z"
            clip-rule="evenodd"
          />
        </svg>
      </label>
    </div>
  </div>
</template>
