<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted, ref } from "vue";
import { useTicketFocus } from "./ticketFocus.ts";
import {
  isFieldVisible2,
  itemSchemaToYupSchema2,
} from "../../form_designer/utils.ts";

import Field from "./Field.vue";

const model = defineModel();

const { item, advanceFromItem } = defineProps(["item", "advanceFromItem"]);

const { hasTicketFocus } = useTicketFocus();

function validateAndAdvance() {
  if (valid) {
    model.value = { ...data.value };

    advanceFromItem(item);
  }
}

const hasFocus = computed(() => hasTicketFocus(`item-${item.id}`));

const data = ref({ ...model.value });
const errors = ref([]);

const validationSchema = itemSchemaToYupSchema2(item.schema);

const valid = computed(() => {
  try {
    validationSchema.validateSync(data.value, { abortEarly: false });
    errors.value = [];
    return true;
  } catch (err) {
    errors.value = err.errors;
    return false;
  }
});

onMounted(() => {
  item.schema.fields.forEach((field) => {
    const fieldName = _.replace(field.name, ".", "_");
    data[fieldName] ||= null;
  });
});
</script>

<template>
  <pre class="font-mono text-xs hidden">Schema: {{ item.schema }}</pre>
  <pre class="font-mono text-xs hidden">Model: {{ model }}</pre>
  <pre class="font-mono text-xs hidden">Data: {{ data }}</pre>

  <div
    class="inspection-item-content pt-4 flex flex-col space-y-6"
    :class="{ hidden: !hasFocus }"
  >
    <template v-for="field in item.schema.fields">
      <Field
        v-if="isFieldVisible2(field, data)"
        :field="field"
        v-model="data[_.replace(field.name, '.', '_')]"
      />
    </template>

    <div v-if="errors" class="hidden flex flex-col space-y-1">
      <span v-for="error in errors" class="text-xs text-red-900">
        {{ error }}
      </span>
    </div>

    <button
      @click.stop="validateAndAdvance"
      :disabled="!valid"
      class="rounded-md w-full py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      :class="{ 'bg-gray-300': !valid, 'bg-indigo-600': valid }"
    >
      Next
    </button>
  </div>
</template>
