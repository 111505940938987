<script setup lang="ts">
import _ from "lodash";
import { computed, inject, onMounted, ref, toRaw } from "vue";

import Database from "./Database";
import PhotoThumbnail from "./PhotoThumbnail.vue";
import PhotoDrop from "./PhotoDrop.vue";

const model = defineModel({ default: ref(null) });

if (_.isUndefined(model.value)) {
  model.value = null;
}

const { label } = defineProps(["label"]);

const ticketId = inject("ticketId");
const db = inject("db") as Database;

const photo = ref(null);
const thumbnailData = ref(null);

function loadPhoto() {
  if (model.value) {
    db.getTicketPhoto(ticketId, model.value).then((p) => {
      photo.value = p;

      if (p?.thumbnailFileUlid) {
        db.getFile(p.thumbnailFileUlid).then((file) => {
          thumbnailData.value = file.data;
        });
      } else {
        thumbnailData.value = null;
      }
    });
  } else {
    photo.value = null;
    thumbnailData.value = null;
  }
}

onMounted(() => {
  if (model.value) {
    loadPhoto();
  }
});

function handlePhotoDrop({ photoUlid }) {
  model.value = photoUlid;
  loadPhoto();
}

const ulid = computed(() => {
  return JSON.parse(JSON.stringify(model.value));
});
</script>

<template>
  <div class="inspection-item-field relative flex flex-col space-y-2">
    <span class="font-semibold text-xl">{{ label }}</span>

    <div class="flex space-x-4">
      <PhotoThumbnail v-if="model" :ulid="toRaw(model)" @remove="model = null" />
      <PhotoDrop label="Photo" :onDrop="handlePhotoDrop" />
    </div>
  </div>
</template>
