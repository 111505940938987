<script setup lang="ts">
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const { dismiss } = defineProps(["dismiss"]);

const modalEl = ref(null);

onClickOutside(modalEl, () => dismiss())
</script>

<template>
  <Teleport to="#service-console-overlay">
    <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="fixed inset-0 bg-gray-500/75 transition-opacity" aria-hidden="true"></div>

      <div class="fixed inset-0 z-10 w-full overflow-y-auto">
        <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div ref="modalEl" class="relative object-contain transform overflow-hidden rounded-lg bg-white p-12 text-left shadow-xl transition-all w-fit h-fit max-w-sm max-h-sm sm:my-8 sm:p-6">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
