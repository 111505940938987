import { inject, nextTick, provide, ref, Ref } from "vue";

export function provideTicketFocus(initialValue: any) {
  const elements = ref({});
  const currentTicketFocus = ref(initialValue);

  provide("elements", elements);
  provide("currentTicketFocus", currentTicketFocus);

  return currentTicketFocus;
}

export function useTicketFocus() {
  const currentTicketFocus = inject("currentTicketFocus") as Ref<any>;
  const elements = inject("elements") as Ref<{ [key: string]: HTMLElement }>;

  return {
    currentTicketFocus,
    registerTicketElement: (key: string, el: HTMLElement) =>
      (elements.value[key] = el),
    hasTicketFocus: (key: string) => currentTicketFocus.value === key,
    setTicketFocus: (key: string) => {
      currentTicketFocus.value = key;
      nextTick(() =>
        elements.value[key].scrollIntoView({ behavior: "smooth" }),
      );
    },
  };
}
