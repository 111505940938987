<script setup lang="ts">
import _ from "lodash";
import { computed, onMounted, ref } from "vue";
import { onClickOutside } from "@vueuse/core";
import { monitorForElements } from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import { extractClosestEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import { reorderWithEdge } from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/reorder-with-edge";
import Field from "./Field.vue";
import FieldForm from "./FieldForm.vue";

const model = defineModel();

function generateId() {
  return Math.random().toString(36).substr(2, 6);
}

model.value.fields.forEach((field) => {
  field.id = generateId();
});

const selectedFieldId = ref<string | null>(null);
const selectedField = computed(() => {
  if (selectedFieldId.value) {
    return model.value.fields.find(
      (field) => field.id === selectedFieldId.value,
    );
  }
});

const containerEl = ref(null);
onClickOutside(containerEl, () => (selectedFieldId.value = null));

let cleanup = () => {};
onMounted(() => {
  cleanup = monitorForElements({
    canMonitor({ source }) {
      return true;
    },
    onDragStart: ({ source }) => {
      selectedFieldId.value = source.data.fieldId;
    },
    onDrop({ location, source }) {
      const target = location.current.dropTargets[0];
      if (!target) {
        return;
      }

      const sourceData = source.data;
      const targetData = target.data;

      const indexOfSource = model.value.fields.findIndex(
        (field) => field.id === sourceData.fieldId,
      );
      const indexOfTarget = model.value.fields.findIndex(
        (field) => field.id === targetData.fieldId,
      );

      if (indexOfTarget < 0 || indexOfSource < 0) {
        return;
      }

      const closestEdgeOfTarget = extractClosestEdge(targetData);

      model.value.fields = reorderWithEdge({
        list: model.value.fields,
        startIndex: indexOfSource,
        indexOfTarget,
        closestEdgeOfTarget,
        axis: "vertical",
      });
    },
  });
});

function addField(attrs) {
  const newFieldId = generateId();

  model.value.fields.push({
    id: newFieldId,
    type: "text",
    required: true,
    visibility: { when: "always", conditions: [] },
    flaggingRule: { mode: "any", conditions: [] },
    ...attrs,
  });

  selectedFieldId.value = newFieldId;
}

function removeSelectedField() {
  if (confirm("Are you sure?")) {
    _.remove(model.value.fields, (field) => field.id === selectedFieldId.value);
    selectedFieldId.value = null
  }
}
</script>

<template>
  <div
    ref="containerEl"
    class="flex divide-x divide-zinc-300 border border-zinc-300 bg-gray-100"
  >
    <div class="w-1/3 flex flex-col gap-2 divide-y">
      <div class="bg-base m-4 p-4 flex flex-col space-y-2">
        <Field
          v-for="field in model.fields"
          :key="field.id"
          :field="field"
          :selected="selectedFieldId === field.id"
          @mousedown="() => (selectedFieldId = field.id)"
        />
      </div>

      <div class="p-2 flex flex-wrap gap-2">
        <button
          type="button"
          @click="() => addField({ type: 'brake_pad_measurement' })"
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add brake pad measurement field
        </button>
        <button
          type="button"
          @click="() => addField({ type: 'photos', required: { min: 1 } })"
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add photos field
        </button>
        <button
          type="button"
          @click="() => addField({ type: 'photo' })"
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add photo field
        </button>
        <button
          type="button"
          @click="() => addField({ type: 'photos', required: { min: 1 } })"
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add photos field
        </button>
        <button
          type="button"
          @click="
            () =>
              addField({ type: 'select', options: [{ label: '', value: '' }] })
          "
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add select field
        </button>
        <button
          type="button"
          @click="() => addField({ type: 'text' })"
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add text field
        </button>
        <button
          type="button"
          @click="
            () =>
              addField({
                type: 'tire_model',
                options: {},
              })
          "
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add tire model field
        </button>
        <button
          type="button"
          @click="
            () =>
              addField({
                type: 'tire_size',
                options: {},
              })
          "
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add tire size field
        </button>
        <button
          type="button"
          @click="
            () =>
              addField({
                type: 'tread_depth',
                options: {},
              })
          "
          class="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Add tread depth field
        </button>
      </div>
    </div>

    <div class="w-2/3 p-4 flex flex-col space-y-4">
      <FieldForm v-if="selectedField" :field="selectedField" />

      <div class="flex justify-end">
        <button type="button" @click="removeSelectedField">Delete field</button>
      </div>
    </div>
  </div>
</template>
